<template>
  <div class="Subscription">
    <div class="card">
      <div class="card-body">
        <subscription v-if="user.subscription_status !== 'lifetime'" />
        <div v-else>
          You have life time subscription. Your current monitors limit is: {{user.limits ? user.limits.monitors : user.monitors_limit}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subscription from '@/components/Account/Subscription/Subscription.vue'

export default {
  components: {
    Subscription
  },

  metaInfo: {
    title: 'Subscription'
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .Subscription {

  }
</style>
